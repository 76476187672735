a {
  text-decoration: none !important;
}
img{
  object-fit: cover;
}
.pointer {
  cursor: pointer;
}
.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}
.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px !important;
  border: 1px solid #f0f1f5 !important;
  padding: 18.5px 9px 18.5px 52px !important;
}
.react-tel-input .country-list {
  width: 250px !important;
}
.search-input {
  padding-bottom: 25px !important;
  padding-top: 25px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
}
.card-position {
  padding: 12px 30px;
  z-index: 1000;
  position: fixed;
  top: 100px;
  right: 0;
  height: auto;
  background-color: red;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.content-background {
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  border-radius: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.accordion__header--icons {
  font-family: "themify";
  position: absolute;
  right: 3.5625rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}